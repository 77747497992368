import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./AboutImageBoard.module.scss"
import * as aboutStyles from "../About.module.scss"
import ParallaxImage from "./ParallaxImage"
import loadable from "@loadable/component"
const SplitText = loadable(() => import("../../Animated/SplitText"))

const AboutImageBoard = () => {
  const containerRef = useRef(null)
  const data = useStaticQuery(graphql`
    query AboutImageBoardQuery {
      contentfulImageBoardList(name: { eq: "About Image Board" }) {
        height
        imageBoardItems {
          top
          right
          bottom
          left
          mobileTop
          mobileRight
          mobileLeft
          maxWidth
          mobileMaxWidth
          id
          name
          zIndex
          image {
            gatsbyImageData
          }
          hoverImage {
            gatsbyImageData
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        className={[
          aboutStyles.sectionContainer,
          styles.container,
          styles.headerContainer,
        ].join(" ")}
      >
        <header className={styles.header}>
          <SplitText>
            The aesthetic inventing, shutter clickin’, voice composing, moment
            makin’ beachside dream team.
          </SplitText>
        </header>
      </div>

      <div
        className={[styles.imageBoardContainer, styles.container].join(" ")}
        ref={containerRef}
      >
        {data.contentfulImageBoardList.imageBoardItems.map(image => (
          <ParallaxImage
            containerRef={containerRef}
            {...image}
            key={image.id}
          />
        ))}
      </div>
      <div className={styles.divider}>
        <hr />
      </div>
    </>
  )
}

export default AboutImageBoard
