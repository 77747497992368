import React from "react"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import AboutPageHeader from "../components/About/AboutPageHeader"
import AboutImageBanner from "../components/About/AboutImageBanner"
import AboutImageBoard from "../components/About/AboutImageBoard"
import AboutFlagSection from "../components/About/AboutFlagSection"

const AboutPage = () => {
  return (
    <FadeIn>
      <SEO
        title="Us | Who We Are"
        description="We are brand strategists, verbal designers, identity communicators & digital marketing experts."
      />
      <AboutPageHeader />
      <AboutFlagSection />
      <AboutImageBanner />
      <AboutImageBoard />
    </FadeIn>
  )
}

export default AboutPage