import React, { createRef, useEffect } from "react"
import { create } from "@lottiefiles/lottie-interactivity"
import "@lottiefiles/lottie-player"

const LottiePlayer = ({
  component: { jsonFile },
  id,
  container,
  totalFrames,
  actions,
  ...lottieProps
}) => {
  const lottieRef = createRef()

  useEffect(() => {
    lottieRef.current.addEventListener("load", function (e) {
      create({
        mode: "scroll",
        player: `#${id}`,
        container: `#${container}`,
        actions: actions ? actions : [
          {
            visibility: [0, 1],
            type: "seek",
            frames: [0, totalFrames],
          },
        ],
      })
    })
  }, [])

  return (
    <div>
      <lottie-player
        ref={lottieRef}
        id={id}
        mode="normal"
        src={jsonFile.file.url}
        {...lottieProps}
      ></lottie-player>
    </div>
  )
}

export default LottiePlayer
