import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./AboutPageHeader.module.scss"
import * as aboutStyles from "../About.module.scss"
import loadable from "@loadable/component"
import Slider from "react-slick"

import ResponsiveImage from "../../responsiveImage"
const FadeUpAndIn = loadable(() => import("../../Animated/FadeUpAndIn"))

function AboutPageHeader() {
  const slider = useRef(null)
  const whoWeAreRef = useRef(null)
  const whatWeDoRef = useRef(null)
  const data = useStaticQuery(graphql`
    query UsPageHeaderQuery {
      contentfulResponsiveImageList(name: { eq: "Us Header Banner" }) {
        id
        responsive_images {
          images {
            gatsbyImageData
            file {
              details {
                image {
                  height
                  width
                }
                size
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 700,
    infinite: true,
  }

  return (
    <div className={[aboutStyles.sectionContainer, styles.container].join(" ")}>
      <div className={styles.titleContainer}>
        <FadeUpAndIn delay={1.8}>
          <div className={styles.title}>Us.</div>
        </FadeUpAndIn>
      </div>
      <div className={styles.wrapper}>
        <Slider ref={slider} {...settings}>
          {data.contentfulResponsiveImageList.responsive_images.map(({ images }) => (
            <ResponsiveImage
              images={images}
              respondToWidth={true}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default AboutPageHeader
