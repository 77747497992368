// extracted by mini-css-extract-plugin
export var hideForMobile = "AboutPageHeader-module--hide-for-mobile--ORcmg";
export var hideForTablet = "AboutPageHeader-module--hide-for-tablet--fcFWc";
export var hideForLaptop = "AboutPageHeader-module--hide-for-laptop--LrnRq";
export var hideForDesktop = "AboutPageHeader-module--hide-for-desktop--UlenD";
export var hideForExtraLarge = "AboutPageHeader-module--hide-for-extra-large--1ZUxa";
export var showForMobileOnly = "AboutPageHeader-module--show-for-mobile-only--Ixk5g";
export var showForTabletOnly = "AboutPageHeader-module--show-for-tablet-only--D6IOz";
export var showForLaptopOnly = "AboutPageHeader-module--show-for-laptop-only--1UK-p";
export var showForDesktopOnly = "AboutPageHeader-module--show-for-desktop-only--emMjX";
export var showForExtraLargeOnly = "AboutPageHeader-module--show-for-extra-large-only--tjUJ0";
export var fullPageContainer = "AboutPageHeader-module--full-page-container--No2Pu";
export var container = "AboutPageHeader-module--container--3N07x";
export var titleContainer = "AboutPageHeader-module--titleContainer--TD2AI";
export var title = "AboutPageHeader-module--title--7iZJh";
export var wrapper = "AboutPageHeader-module--wrapper---lIZp";
export var links = "AboutPageHeader-module--links--wTgFe";
export var link = "AboutPageHeader-module--link--STWIc";