import React, { useEffect, useRef } from "react"
import * as styles from "./AboutFlagSection.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import LottiePlayer from "../../CaseStudyBlocks/LottiePlayer"
import loadable from "@loadable/component"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
const SplitText = loadable(() => import("../../Animated/SplitText"))

const AboutFlagSection = () => {
  const containerRef = useRef(null)
  const data = useStaticQuery(graphql`
    query LottieQuery {
      contentfulLottieComponent(name: { eq: "HS Brown Flag" }) {
        id
        name
        jsonFile {
          file {
            url
          }
        }
        loop
        renderer
        autoplay
      }
    }
  `)

  const texts = [
    "High quality relationships yield high quality work.",
    "Brand is the ultimate competitive advantage.",
    "Friction makes fire.",
  ]

  const lottieId = "about-flag"
  const lottieActions = [
    {
      visibility: [0, 0.5],
      type: "seek",
      frames: [0, 120],
    },
    {
      visibility: [0.5, 1],
      type: "seek",
      frames: [0, 120],
    },
  ]

  return (
    <>
      <div className={styles.textBanner} id="who-we-are">
        <span className={styles.text}>
          <SplitText>
            We’re best understood through the words we live by...
          </SplitText>
        </span>
      </div>
      <div id={styles.container} ref={containerRef}>
        <div className={styles.lottieContainer}>
          <div className={styles.lottie}>
            <LottiePlayer
              component={{ ...data.contentfulLottieComponent }}
              id={lottieId}
              container={styles.container}
              totalFrames={120}
              loop={true}
              actions={lottieActions}
            />
          </div>
        </div>

        {texts.map(text => (
          <FlagTextContainer key={text}>{text}</FlagTextContainer>
        ))}
      </div>
    </>
  )
}

const FlagTextContainer = ({ children }) => {
  const animation = useAnimation()
  const [ref, inView] = useInView({ rootMargin: '0% 0% -15%' })

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    } else {
      animation.start("hidden")
    }
  }, [animation, inView])

  const variants = {
    hidden: {
      opacity: 0,
      y: 20,
      transition: { duration: 0.5, ease: [0.165, 0.84, 0.44, 1] }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: [0.165, 0.84, 0.44, 1] },
    }
  }

  return (
    <div className={styles.textContainer}>
      <motion.div
        className={styles.text}
        ref={ref}
        exit="exit"
        initial="hidden"
        animate={animation}
        variants={variants}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default AboutFlagSection
