import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./AboutImageBanner.module.scss"
import * as aboutStyles from "../About.module.scss"

import ResponsiveImage from "../../responsiveImage"

function AboutImageBanner() {
  const data = useStaticQuery(graphql`
    query AboutImageBannerQuery {
      contentfulResponsiveImage(title: { eq: "Us Image Banner 2" }) {
        id
        images {
          id
          title
          gatsbyImageData(quality: 70)
          file {
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={[aboutStyles.sectionContainer, styles.container].join(" ")}>
      <ResponsiveImage
        images={data.contentfulResponsiveImage.images}
        respondToWidth={true}
      />
    </div>
  )
}

export default AboutImageBanner
