// extracted by mini-css-extract-plugin
export var hideForMobile = "About-module--hide-for-mobile--PnBMT";
export var hideForTablet = "About-module--hide-for-tablet--U9sei";
export var hideForLaptop = "About-module--hide-for-laptop--HbJE9";
export var hideForDesktop = "About-module--hide-for-desktop--tcZ4J";
export var hideForExtraLarge = "About-module--hide-for-extra-large--Homat";
export var showForMobileOnly = "About-module--show-for-mobile-only--Y1jz-";
export var showForTabletOnly = "About-module--show-for-tablet-only--3eC+y";
export var showForLaptopOnly = "About-module--show-for-laptop-only--I-2VF";
export var showForDesktopOnly = "About-module--show-for-desktop-only--fYrjf";
export var showForExtraLargeOnly = "About-module--show-for-extra-large-only--KACx+";
export var fullPageContainer = "About-module--full-page-container--FaWze";
export var sectionContainer = "About-module--sectionContainer--tMttK";