import React, { useRef, useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import * as styles from "./AboutImageBoard.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import classnames from "classnames"

const imageThreshold = 0.1
const containerThreshold = 200

const ParallaxImage = ({
  containerRef,
  id,
  left,
  top,
  right,
  bottom,
  image,
  maxWidth,
  zIndex,
  hoverImage,
  name,
  ...mobileSpecs
}) => {
  const [hover, setHover] = useState(false)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()

  const calculateY = y => {
    const zPos = zIndex || 1
    const multiplier = zPos * 0.3;
    return -Math.abs(y * multiplier)
  }

  const y = useTransform(
    scrollY,
    currentScrollY => {
      if (!containerRef.current || !ref.current) {
        return null
      }

      // Screen Height
      const screenHeight = window.innerHeight
      // Window Scroll position of image board container
      const containerTop = containerRef.current.offsetTop
      // Relative Scroll position of image element to the container
      const imageTop = ref.current.offsetTop
      // Window scroll position of element
      const imageY = containerTop + imageTop
      // Image height
      const imageHeight = ref.current.querySelector(".gatsby-image-wrapper")
        .offsetHeight
      // scroll threshold for when to trigger transformation
      const thresholdY = imageY + imageHeight * imageThreshold - screenHeight
    
      if (currentScrollY > thresholdY) {
        const translateY = calculateY(currentScrollY - thresholdY)

        if (
          currentScrollY > thresholdY + screenHeight &&
          currentScrollY > 400
        ) {
          // Limits the movement of an image to one screen height with a min screen height of 400
          return calculateY(screenHeight)
        }

        return translateY
      }

      return 0
    },
    {
      clamp: false,
      ease: [0.165, 0.84, 0.44, 1],
    }
  )

  const hasMobile = item => {
    const {
      mobileTop,
      mobileRight,
      mobileBottom,
      mobileLeft,
      mobileMaxWidth,
    } = item
    if (
      mobileTop ||
      mobileRight ||
      mobileBottom ||
      mobileLeft ||
      mobileMaxWidth
    ) {
      return true
    }

    return false
  }

  const imageWrapperStyle = classnames(styles.imageWrapper, {
    [styles.left]: left !== null ? true : false,
    [styles.right]: right !== null ? true : false,
  })

  const hoverImageStyle = classnames(styles.hoverImage, {
    [styles.show]: hover,
  })

  const handleHoverIn = e => {
    e.preventDefault()
    setHover(true)
  }

  const handleHoverOut = e => {
    e.preventDefault()
    setHover(false)
  }

  return (
    <>
      <motion.div
        style={{
          width: maxWidth,
          top: `${top}%`,
          right: `${right}%`,
          left: `${left}%`,
          zIndex,
          y
        }}
        id={`image-board-component-${name}`}
        ref={ref}
        className={imageWrapperStyle}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
        data-speed={`${zIndex}`}
      >
        <GatsbyImage style={{ position: "absolute" }} image={image.gatsbyImageData} alt={name || ''} />
        {hoverImage && (
          <GatsbyImage image={hoverImage.gatsbyImageData} className={hoverImageStyle} alt={name || ''} />
        )}
      </motion.div>
      {hasMobile(mobileSpecs) && (
        <div
          style={{
            position: "absolute",
            width: mobileSpecs.mobileMaxWidth,
            top: `${mobileSpecs.mobileTop}%`,
            right: `${mobileSpecs.mobileRight}%`,
            bottom: `${mobileSpecs.mobileBottom}%`,
            left: `${mobileSpecs.mobileLeft}%`,
            zIndex,
          }}
          className={styles.mobileImageWrapper}
          onMouseEnter={handleHoverIn}
          onMouseLeave={handleHoverOut}
        >
          <GatsbyImage
            style={{
              top: `${mobileSpecs.mobileTop}%`,
              right: `${mobileSpecs.mobileRight}%`,
              bottom: `${mobileSpecs.mobileBottom}%`,
              left: `${mobileSpecs.mobileLeft}%`,
            }}
            alt={name || ''}
            image={image.gatsbyImageData}
          />
          {hoverImage && (
            <GatsbyImage image={hoverImage.gatsbyImageData} alt={name || ''} className={hoverImageStyle} />
          )}
        </div>
      )}
    </>
  )
}

export default ParallaxImage
