// extracted by mini-css-extract-plugin
export var hideForMobile = "AboutFlagSection-module--hide-for-mobile--bF3wa";
export var hideForTablet = "AboutFlagSection-module--hide-for-tablet--YnK9E";
export var hideForLaptop = "AboutFlagSection-module--hide-for-laptop--EOUnT";
export var hideForDesktop = "AboutFlagSection-module--hide-for-desktop--f5npB";
export var hideForExtraLarge = "AboutFlagSection-module--hide-for-extra-large--a5c+p";
export var showForMobileOnly = "AboutFlagSection-module--show-for-mobile-only--rGquY";
export var showForTabletOnly = "AboutFlagSection-module--show-for-tablet-only--zuWir";
export var showForLaptopOnly = "AboutFlagSection-module--show-for-laptop-only--1c-F6";
export var showForDesktopOnly = "AboutFlagSection-module--show-for-desktop-only--G+JEA";
export var showForExtraLargeOnly = "AboutFlagSection-module--show-for-extra-large-only--7lxX8";
export var fullPageContainer = "AboutFlagSection-module--full-page-container--L36vU";
export var container = "AboutFlagSection-module--container--bnPYy";
export var lottieContainer = "AboutFlagSection-module--lottieContainer--LjieF";
export var lottie = "AboutFlagSection-module--lottie--v+7fd";
export var textContainer = "AboutFlagSection-module--textContainer--65vkV";
export var text = "AboutFlagSection-module--text--0zu0b";
export var textBanner = "AboutFlagSection-module--textBanner--AhE1Y";