// extracted by mini-css-extract-plugin
export var hideForMobile = "AboutImageBoard-module--hide-for-mobile--8aBkA";
export var hideForTablet = "AboutImageBoard-module--hide-for-tablet--240bH";
export var hideForLaptop = "AboutImageBoard-module--hide-for-laptop--VWQgd";
export var hideForDesktop = "AboutImageBoard-module--hide-for-desktop--xBdM6";
export var hideForExtraLarge = "AboutImageBoard-module--hide-for-extra-large--8+ZyP";
export var showForMobileOnly = "AboutImageBoard-module--show-for-mobile-only--Zgnem";
export var showForTabletOnly = "AboutImageBoard-module--show-for-tablet-only--9kELZ";
export var showForLaptopOnly = "AboutImageBoard-module--show-for-laptop-only--r0PmX";
export var showForDesktopOnly = "AboutImageBoard-module--show-for-desktop-only--96Hn0";
export var showForExtraLargeOnly = "AboutImageBoard-module--show-for-extra-large-only--nfNUJ";
export var fullPageContainer = "AboutImageBoard-module--full-page-container--XQjD3";
export var container = "AboutImageBoard-module--container--25igY";
export var headerContainer = "AboutImageBoard-module--headerContainer--rbPju";
export var header = "AboutImageBoard-module--header--O2Lb-";
export var imageBoardContainer = "AboutImageBoard-module--imageBoardContainer--l12Y0";
export var imageWrapper = "AboutImageBoard-module--imageWrapper--1EPP+";
export var left = "AboutImageBoard-module--left--WwSHE";
export var right = "AboutImageBoard-module--right--2lRJO";
export var mobileImageWrapper = "AboutImageBoard-module--mobileImageWrapper--heRPI";
export var hoverImage = "AboutImageBoard-module--hoverImage--Lr8jz";
export var show = "AboutImageBoard-module--show--jDwV2";
export var divider = "AboutImageBoard-module--divider--k9UtO";